import axios from "axios";
import { store } from "MainApp";

// Configure axios interceptors
export const reportLoadingInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      if (response?.data && typeof response.data === "object") {
        /**
         * Only update the report loading state if the flag is explicitly present
         * This prevents unrelated API calls from affecting the loading state
         */
        if ("is_report_data_loading" in response.data) {
          store.dispatch({
            type: "SET_REPORT_LOADING",
            payload: response.data.is_report_data_loading,
          });
        }
        /**
         * Only update the last loaded date if the property is explicitly present
         * This prevents unrelated API calls from affecting this state
         */
        if ("last_report_data_loaded_date" in response.data) {
          store.dispatch({
            type: "SET_LAST_REPORT_DATA_LOADED_DATE",
            payload: response.data.last_report_data_loaded_date,
          });
        }
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
