import React, { Component, useEffect, useState } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";
import { initializeInterceptors } from "../util/interceptors";

import MainApp from "app/index";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import {
  setInitUrl,
  userSignInSuccess,
  userPreferredLguage,
} from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";

import WoVoSurveyResponse from "./SurveyResponse/WovoSurveyResponse";
import AppUtils from "../util/util";
// import StorageHandler from "../util/storageHandler";
import { setUser, saveSearchParam } from "../actions";
import { getTranslationsList } from "../actions/adminSettings/translations/strings/actionsTranslationStrings";
import TagManager from "react-gtm-module";
import DownTimeBanner from "components/DownTimeBanner";
import { prepareUserMenu } from "../util";
import { baseUrl } from "util/keys";
import StorageHandler from "util/storageHandler";
import Axios from "axios";
import { HTTP_TIMEOUT, URL_LOGIN_VALIDATE } from "constants/Urls";
import { WOVO_USER_ROLES } from "constants/userRoles";

const getSignedInUser = async () => {
  const accessToken = StorageHandler.getCookie("access_token");
  const useCookieToValidate =
    process.env.REACT_APP_USE_COOKIES_TO_AUTHENTICATE === "true";
  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
      [`${!useCookieToValidate ? "Authorization" : "withCredentials"}`]: `${
        !useCookieToValidate ? `Bearer ${accessToken}` : true
      }`,
    },
    timeout: HTTP_TIMEOUT,
  };
  const response = await Axios.get(`${baseUrl}${URL_LOGIN_VALIDATE}`, headers)
    .then((authUser) => authUser)
    .catch((error) => error);

  if (response.status == 200 && response.data.user) {
    let user = response.data.user;
    let avatarName = "";
    let isSuperAdmin = false;

    if (
      typeof user["first_name"] != "undefined" &&
      user["first_name"] != null
    ) {
      avatarName = user["first_name"].substring(0, 1).toUpperCase();
    }

    if (typeof user["last_name"] != "undefined" && user["last_name"] != null) {
      avatarName += user["last_name"].substring(0, 1).toUpperCase();
    }

    if (
      typeof user["is_super_admin"] != "undefined" &&
      user["is_super_admin"] != null
    ) {
      isSuperAdmin = user["is_super_admin"];
    }

    let currentUserRole = response.data.user["role"];
    let isUserHasTemplateCreateAccess = false;

    if (
      currentUserRole === WOVO_USER_ROLES.SUPER_ADMIN ||
      currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER ||
      currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_PHASE_1 ||
      currentUserRole ===
        WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_UNDER_ARMOUR ||
      currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_TCP
    ) {
      isUserHasTemplateCreateAccess = true;
    }

    const accessToken = `Bearer ${response.data.access}`;
    const useCookieToValidate =
      process.env.REACT_APP_USE_COOKIES_TO_AUTHENTICATE === "true";
    if (!useCookieToValidate) {
      StorageHandler.setCookie("access_token", response.data.access);
    }

    let userState = {
      user: user,
      avatarName: avatarName,
      authUser: response.data,
      currentUserRole: currentUserRole,
      bearerHeader: { Authorization: accessToken },
      refreshKey: response.data.refresh,
      accessToken: accessToken,
    };

    if (
      typeof user["preferred_client"] != "undefined" &&
      StorageHandler.getItem(StorageHandler.keys.PREFERRED_CLIENT) !== ""
    ) {
      StorageHandler.saveItem(
        StorageHandler.keys.PREFERRED_CLIENT,
        user["preferred_client"]
      );
    }

    StorageHandler.saveCurrentUserName(user["user_name"]);
    StorageHandler.saveUserState(user["user_name"], userState);

    return {
      ...userState,
      isUserHasTemplateCreateAccess: isUserHasTemplateCreateAccess,
      isSuperAdmin: isSuperAdmin,
      menuConfiguration: prepareUserMenu(user, isSuperAdmin),
      access: accessToken,
    };
  }
  return null;
};

const getAuthUser = async () => {
  return await getSignedInUser();
};

const RestrictedRoute = ({
  component: Component,
  authUser,
  setUser,
  getTranslationsList,
  path,
  remoteTranslations,
  user,
  ...rest
}) => {
  const [userFetched, setUserFetched] = useState(false);
  const dispatch = useDispatch();

  let fetchedAuthUser;
  useEffect(() => {
    if (!authUser || (authUser && !authUser.access)) {
      getAuthUser().then((fetchedUser) => {
        if (fetchedUser) {
          fetchedAuthUser = fetchedUser;
          setUser(fetchedUser);
          getTranslationsList();
        }
        setUserFetched(true);
      });
    } else {
      setUserFetched(true);
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(remoteTranslations).length > 0 &&
      Object.keys(user).length > 0
    ) {
      dispatch(userPreferredLguage(user));
    }
  }, [remoteTranslations, user]);

  const loginUser = fetchedAuthUser || authUser;

  if (!userFetched) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src="/vendors/wovo/wovo.svg" height={50} />
        <br />
        <span>Loading...</span>
      </div>
    );
  }

  if (!loginUser || (loginUser && !loginUser.access)) {
    if (path === "/signin") {
      return (
        <Route
          path="/signin"
          {...rest}
          render={(props) => <Component {...props} />}
        />
      );
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser || fetchedAuthUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class App extends Component {
  componentDidMount() {
    // Initialize all interceptors
    initializeInterceptors();
    /**Initialize Google Tag manager */
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_KEY,
    };
    TagManager.initialize(tagManagerArgs);
  }

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }

    if (typeof this.props.history.location.search !== "undefined") {
      this.props.saveSearchParam(this.props.history.location.search);
    }
  }

  render() {
    const {
      match,
      location,
      locale,
      authUser,
      initURL,
      isDirectionRTL,
      user,
      getTranslationsList,
      setUser,
      remoteTranslations,
    } = this.props;

    /*if (typeof user == "undefined" || (typeof user != "undefined" && typeof user['user_name'] == "undefined")) {

            let userState = StorageHandler.getUserState();

            if (typeof userState['user'] != "undefined") {
                this.props.getTranslationsList();
                this.props.setUser(userState);
                if (location.pathname !== "/app/dashboard") {
                    window.location = window.location.origin + "/app/dashboard";
                }
            }
        }*/

    if (
      location.pathname === "/" &&
      location.pathname !== "/survey_response/"
    ) {
      if (authUser === null || typeof user.first_name == "undefined") {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/app/dashboard"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    } else if (location.pathname === "/survey_response/") {
      AppUtils.setSurveyResponseParams(this.props.location.search);
      return <Redirect to={"/survey_response"} />;
    }

    const applyTheme = createTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <DownTimeBanner>
                <div className="app-main">
                  <Switch>
                    <RestrictedRoute
                      path={`${match.url}app`}
                      authUser={authUser}
                      component={MainApp}
                      getTranslationsList={getTranslationsList}
                      setUser={setUser}
                      remoteTranslations={remoteTranslations}
                      user={user}
                    />
                    <RestrictedRoute
                      path="/signin"
                      component={SignIn}
                      authUser={authUser}
                      getTranslationsList={getTranslationsList}
                      setUser={setUser}
                      remoteTranslations={remoteTranslations}
                      user={user}
                    />
                    <Route path="/signup" component={SignUp} />
                    <Route
                      path="/survey_response"
                      component={WoVoSurveyResponse}
                    />
                    <Route
                      component={asyncComponent(() =>
                        import("components/Error404")
                      )}
                    />
                  </Switch>
                </div>
              </DownTimeBanner>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL, remoteTranslations } = settings;
  const { authUser, initURL, user } = auth;
  return {
    sideNavColor,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
    user,
    remoteTranslations,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  setUser,
  getTranslationsList,
  saveSearchParam,
})(App);
